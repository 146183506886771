// JavaScript
//
//
//
//(function ($, window, document, undefined) {
//    'use strict';
//}(jQuery, window, window.document));
// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

$(document)
    .on('open.fndtn.offcanvas', '[data-offcanvas]', function() {
        //$('html').css('overflow', 'hidden');
        $('#sitemapButton').addClass('fa-rotate-270');
        //alert('off canvas');
        //console.log('off canvas');
    })
    .on('close.fndtn.offcanvas', '[data-offcanvas]', function() {
        //$('html').css('overflow', 'auto');
        $('#sitemapButton').removeClass('fa-rotate-270');
        //alert('on canvas');
        //console.log('on canvas');
    });
/*
 <li><a href="#" title="Sitemap" class="right-off-canvas-toggle"><i id="sitemap" class="fa fa-sitemap"></i></a></li>
 <!--
 <a class="right-off-canvas-toggle" href="#" >Menu</a>
 <p><i class="fa fa-sitemap fa-rotate-270"></i> sitemap</p>

 */

$("#site-bar").scrollupbar();